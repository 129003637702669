import { createRouter, createWebHistory } from "vue-router";
import useAuth from "@/modules/auth/compositions/useAuth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/app/views/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/modules/auth/views/LoginPage.vue"
      )
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { isAuthRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/modules/admin/views/AdminDashboard.vue"
      ),
    children: [
      {
        path: "/admin/page/new",
        name: "PageCreator",
        meta: { isAuthRequired: true },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@/modules/admin/views/PageCreator.vue"
          )
      },
      {
        path: "/admin/:slug",
        name: "PageEditor",
        meta: { isAuthRequired: true },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@/modules/admin/views/PageEditor.vue"
          )
      }
    ]
  },
  {
    path: "/:slug",
    name: "Page",
    component: () =>
      import(
        /* webpackChunkName: "page" */ "@/modules/pages/views/FullPage.vue"
      )
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, _, next) => {
  const { isUserSignedIn } = useAuth();
  const isAuthRequired = to.matched.some(
    record => record.meta.isAuthRequired === true
  );

  let nextPath = true;

  if (isAuthRequired && !isUserSignedIn.value) {
    nextPath = "/login";
  } else if (isUserSignedIn.value && to.path === "/login") {
    nextPath = "/admin";
  }

  next(nextPath);
});

export default router;
