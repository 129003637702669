import { ref, readonly } from "vue";
import { pagesCollection } from "@/plugins/firebase";
import usePageDocumentMapper from "@/modules/core/compositions/usePageDocumentMapper";

const userPages = ref(null);

export default function useUserPages() {
  const { mapPageFromDocument } = usePageDocumentMapper();

  // Private utilities
  const loadAllPages = async () => {
    let collectionData = await pagesCollection.get();

    userPages.value = collectionData.docs.map(doc => {
      return mapPageFromDocument(doc);
    });
  };

  const getUserPages = async () => {
    if (userPages.value === null) {
      await loadAllPages();
    }

    return userPages;
  };

  // Public API

  const fetchAllPages = async () => {
    const pages = await getUserPages();
    return readonly(pages);
  };

  const addPage = async pageCreationData => {
    // Check weather document exists
    const normalizedPageSlug = pageCreationData.slug.replace(/\s/g, "");
    const reservedSlugs = ["admin", "login"];

    if (reservedSlugs.includes(normalizedPageSlug)) {
      throw new Error(`This page slug is already taken`);
    }

    const pageDoc = await pagesCollection.doc(normalizedPageSlug).get();

    if (pageDoc.exists) {
      throw new Error(`This page slug is already taken`);
    }

    // Create a new document
    let pageTemplate = require("@/assets/newPageTemplate.json");
    pageTemplate.header.title = pageCreationData.name;

    await pagesCollection.doc(normalizedPageSlug).set(pageTemplate);

    // Get new document details
    const doc = await pagesCollection.doc(normalizedPageSlug).get();
    const newPage = mapPageFromDocument(doc);

    // Update local state
    const userPages = await getUserPages();
    userPages.value.push(newPage);
    userPages.value.sort((p1, p2) => p1.slug.localeCompare(p2.slug));

    return readonly(newPage);
  };

  const updatePage = async page => {
    // Update page
    await pagesCollection.doc(page.slug).set(page.data);

    // Update local state
    const userPages = await getUserPages();
    const pageIndex = userPages.value.findIndex(
      localPage => localPage.slug == page.slug
    );

    if (pageIndex !== -1) {
      userPages.value[pageIndex] = page;
    }

    return page;
  };

  const deletePage = async slug => {
    // Remove page
    await pagesCollection.doc(slug).delete();

    // Update local state
    const userPages = await getUserPages();
    const pageIndex = userPages.value.findIndex(
      localPage => localPage.slug == slug
    );

    if (pageIndex !== -1) {
      userPages.value.splice(pageIndex, 1);
    }
  };

  const clearCurrentState = () => {
    userPages.value = null;
  };

  return {
    userPages: readonly(userPages),
    fetchAllPages,
    addPage,
    updatePage,
    deletePage,
    clearCurrentState
  };
}
