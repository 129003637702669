export default function usePageDocumentMapper() {
  const mapPageFromDocument = doc => {
    const docData = doc.data();
    return {
      slug: doc.id,
      data: {
        header: docData.header,
        links: docData.links,
        iconLinks: docData.iconLinks,
        theme: docData.theme
      }
    };
  };

  return {
    mapPageFromDocument
  };
}
