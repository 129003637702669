<template>
  <div
    class="flex flex-col flex-grow items-center justify-center w-full h-full space-y-10 bg-gray-100 text-gray-500 font-medium"
  >
    <RIcon size="fa-4x" name="fas fa-satellite fa-5x " />
    <h1 v-if="message" class="text-4xl text-center">{{ message }}</h1>
  </div>
</template>

<script>
import RIcon from "@/modules/core/components/RIcon";

export default {
  name: "SuspenseLoadingIndicator",
  components: {
    RIcon
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
