<template>
  <router-view v-slot="{ Component }">
    <FullScreenLayout>
      <Suspense timeout="0">
        <template #default>
          <component :is="Component" />
        </template>
        <template #fallback>
          <SuspenseLoadingIndicator />
        </template>
      </Suspense>
    </FullScreenLayout>
  </router-view>
</template>

<script>
import FullScreenLayout from "@/modules/core/layouts/FullScreenLayout";
import SuspenseLoadingIndicator from "@/modules/core/components/SuspenseLoadingIndicator";

export default {
  components: {
    FullScreenLayout,
    SuspenseLoadingIndicator
  }
};
</script>
