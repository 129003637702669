import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

let firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

let firebaseApp = undefined;
if (!firebase.apps.length || !firebaseApp) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

// firebase utils
const auth = firebase.auth();
const db = firebase.firestore();

//collections
const pagesCollection = db.collection("pages");

export { auth, db, pagesCollection };
