<template>
  <span>
    <i v-bind:class="iconClass"></i>
  </span>
</template>

<script>
export default {
  name: "RIcon",
  props: {
    name: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "fa-lg"
    }
  },
  setup(props) {
    const iconClass = props.name + " " + props.size;

    return { iconClass };
  }
};
</script>
