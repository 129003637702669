import { ref, readonly, computed } from "vue";
import { auth } from "@/plugins/firebase";
import useUserPages from "@/modules/admin/compositions/useUserPages";

const user = ref(null);

export default function useAuth() {
  const { clearCurrentState } = useUserPages();
  const error = ref(null);
  const isLoading = ref(false);

  const signInWithEmailAndPassword = async credentials => {
    isLoading.value = true;
    error.value = null;

    try {
      const userData = await auth.signInWithEmailAndPassword(
        credentials.username,
        credentials.password
      );

      setUser(userData);
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  const signOut = async () => {
    isLoading.value = true;
    error.value = null;

    try {
      await auth.signOut();
      setUser(null);
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  const setUser = userData => {
    if (!userData) {
      user.value = null;
      clearCurrentState();
      return;
    }

    user.value = {
      id: userData.uid,
      name: userData.displayName,
      photoUrl: userData.photoURL,
      email: userData.email
    };
  };

  return {
    user: readonly(user),
    error: readonly(error),
    isLoading: readonly(isLoading),
    isUserSignedIn: computed(() => user.value != null),
    signInWithEmailAndPassword,
    setUser,
    signOut
  };
}
