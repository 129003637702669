import { createApp } from "vue";
import App from "./app/App.vue";
import router from "./app/router";
import { auth } from "./plugins/firebase";
import useAuth from "@/modules/auth/compositions/useAuth";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/assets/styles.css";

let app = undefined;
auth.onAuthStateChanged(userData => {
  if (userData) {
    const { setUser } = useAuth();
    setUser(userData);
  }
  if (!app) {
    app = createApp(App)
      .use(router)
      .mount("#app");
  }
});
